import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get } from 'lodash/fp'

import { Layout } from 'src/components/Layout'

import { PageTitle } from 'src/slices/PageTitle'
import { BodyCopy } from 'src/slices/BodyCopy'
import { NotificationBar } from 'src/slices/NotificationBar'
import { Summary } from 'src/slices/Summary'

const GoodFaithEstimatePage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>

      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      {get('frontmatter.notice', page) && (
        <NotificationBar>{get('frontmatter.notice', page)}</NotificationBar>
      )}
      {get('frontmatter.description', page) && (
        <Summary markdown={get('frontmatter.description', page)} />
      )}
      <BodyCopy
        heading={get('frontmatter.subtitle', page)}
        markdown={get('rawMarkdownBody', page)}
        backTo={get('frontmatter.back_button_url', page)}
      />
    </Layout>
  )
}

export default GoodFaithEstimatePage

export const query = graphql`
  query GoodFaithEstimatePage {
    file(relativePath: { eq: "customPages/good-faith-estimate.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subtitle
          notice
          description
          back_button_url
        }
        rawMarkdownBody
      }
    }
  }
`
